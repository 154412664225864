
import Vue from 'vue';
export default {
  name: "Login",
  data() {
    return {
      form: {
        name: "",
        password: "",
      },
      message: "",
      success: false,
    };
  },
  async created() {
    await this.$store.dispatch("user/initUser");
  },
  computed: {
    userInfo() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async login() {
      const params = new URLSearchParams();
      params.append("name", this.form.name);
      params.append("pwd", this.form.password);

      try {
        const response = await this.$api.userLogin(params);

        if (response.success) {
          Vue.prototype.$toast.success('登录成功', {
            position: 'top-right',
            timeout: 3000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            showCloseButtonOnHover: true,
            hideProgressBar: false,
            closeButton: 'button',
            icon: true,
          });
          this.message = "登录成功，数据加载中";
          this.form.password = "";
          this.success = true;

          // 将用户信息存储到 localStorage
          if (process.client) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
          // 将用户信息存储到 Vuex Store
          this.$store.dispatch("user/login", response.data);

          // 跳转到其他页面
          this.$router.push("/");
        } else {
          this.message = response.message || "登录失败";
          this.success = false;
        }
      } catch (error) {
        this.message = error.response.data.message || "登录失败";
        this.success = false;
      }
    },
    logout() {
      this.$store.dispatch("user/logout");
      this.message = "已退出登录";
      this.success = true;
      this.$router.push("/"); // 跳转到首页或其他页面
    },
  },
};
